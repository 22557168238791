.footer-container {
  width: 100%;
  height: 100px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  bottom: 0;
  background: none;
}

.icon {
  height: 40px;
  width: 40px;
  margin: 10px;
  fill: rgb(65, 105, 225);
}

.icon:hover {
  height: 45px;
  width: 45px;
}

#linked-in-id {
  margin-right: 5vw;
}

.link,
.contact {
  margin-right: 20px;
}

.contact {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: rgb(61, 61, 143);
}

@media screen and (max-width: 500px) {
  .footer-container {
    justify-content: space-around;
    position: relative;
  }
  #linked-in-id {
    margin-right: 0;
  }
  .icon {
    width: 30px;
    height: 30px;
  }
}

@media screen and (min-width: 1400px) {
  .footer-container {
    max-width: 1400px;
    position: relative;
    margin: 0 auto;
  }
}
