.landing-page {
  position: absolute;
  top: 0;
  background: transparent;
  height: 100vh;
  width: 100vw;
}

.triangle {
  margin-top: 75px;
  margin-left: 10vw;
  width: 0;
  height: 0;
  border-left: 200px solid transparent;
  border-right: 60vw solid transparent;
  border-top: 600px solid rgba(244, 5, 5, 0.75);
}

#name-blurb {
  position: absolute;
  width: 500px;
  top: 120px;
  left: 17%;
  color: white;
}

.my-name {
  font-family: "Paytone One", sans-serif;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 5px;
  color: rgb(247, 209, 245);
}

.blurb {
  font-family: "Quicksand", sans-serif;
  margin-top: 5px;
}

#projects {
  position: absolute;
  top: 20%;
  right: 15%;
  width: 450px;
  height: 450px;
  border-radius: 50%;
  background-color: rgb(65, 105, 225, 0.7);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(247, 209, 245);
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
}

#projects:hover,
.my-name:hover {
  cursor: pointer;
  color: rgb(171, 84, 166);
}

#resume {
  position: absolute;
  bottom: 31%;
  left: 10%;
  height: 60px;
  width: 60%;
  background-color: rgb(255, 255, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(61, 61, 143);
  font-family: "Quicksand", sans-serif;
  font-size: 20px;
}

#about {
  position: absolute;
  bottom: 21%;
  right: 10%;
  height: 60px;
  width: 60%;
  background-color: rgb(255, 255, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(61, 61, 143);
  font-family: "Quicksand", sans-serif;
  font-size: 20px;
}

@media screen and (max-width: 1350px) {
  #projects {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 1150px) {
  #name-blurb {
    top: 100px;
  }
  #projects {
    right: 5%;
  }
}

@media screen and (max-width: 1000px) {
  .triangle {
    margin-top: 50px;
    margin-left: 10vw;
  }
  #name-blurb {
    top: 50px;
  }
  #projects {
    width: 225px;
    height: 225px;
    top: 27%;
    right: 27%;
  }
}

@media screen and (max-width: 500px) {
  .landing-page {
    height: 100vh;
  }
  .triangle {
    border-right: 50vw solid transparent;
    border-left: 100px solid transparent;
  }
  #landing-my-name {
    font-size: 30px;
    width: 60%;
    margin-left: 10%;
  }
  #name-blurb {
    width: 100%;
    left: 0%;
    color: transparent;
  }
  #projects {
    right: 25px;
    top: 23%;
    width: 200px;
    height: 200px;
  }
  #resume,
  #about {
    width: 75%;
  }
}

@media screen and (min-width: 1400px) {
  .landing-page {
    width: 1400px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 2000px) {
  .triangle {
    border-right: 1200px solid transparent;
    margin-left: 0;
  }
}
