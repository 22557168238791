.projects-page {
  height: 100%;
  width: 100vw;
}

.projects-container {
  height: 100%;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 100px;
}

@media screen and (max-width: 800px) {
  .projects-container {
    height: 100%;
    align-items: center;
  }
  .footer-container {
    position: relative;
  }
}

@media screen and (min-width: 1200px) {
  .projects-page {
    width: 1200px;
    margin: 0 auto;
  }
}
