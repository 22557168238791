.contact-me {
  width: 100%;
  height: 100vh;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 0 auto;
}

.form-label {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  margin-top: 10px;
  color: rgb(61, 61, 143);
}

#text-area {
  height: 150px;
  overflow: auto;
  padding: 10px;
}

.form-input {
  margin-top: 5px;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  line-height: 1.7;
  background-color: rgb(254, 241, 220);
  border-radius: 5px;
  border: none;
}

.submit-button {
  width: 100px;
  height: 30px;
  margin: 30px auto;
  font-family: "Paytone One", sans-serif;
  border: none;
  border-radius: 5px;
  color: rgb(254, 241, 220);
  background-color: rgb(61, 61, 143);
}
