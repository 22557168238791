.my-photo {
  margin-right: 50px;
  width: 400px;
  clip-path: polygon(
    50% 0%,
    90% 20%,
    100% 60%,
    75% 100%,
    25% 100%,
    0% 60%,
    10% 20%
  );
}

.about-page {
  height: 100vh;
}

.about-all {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.about-text {
  width: 600px;
  text-align: left;
  font-family: "Quicksand", sans-serif;
  line-height: 1.5;
}

@media screen and (max-width: 800px) {
  .my-photo {
    margin-right: 0;
    width: 40vw;
  }
  .about-all {
    flex-direction: column;
  }
}
