#project-1 {
  background-image: url(https://raw.githubusercontent.com/suttondemlong/portfolio/main/src/assets/ParksAlert.png);
}

#project-2 {
  background-image: url(https://raw.githubusercontent.com/suttondemlong/portfolio/main/src/assets/PaintedGrits.png);
}

#project-3 {
  background-image: url(https://raw.githubusercontent.com/suttondemlong/portfolio/main/src/assets/GreenHouse.png);
}

#project-4 {
  background-image: url(https://github.com/suttondemlong/portfolio/blob/main/src/assets/ThoughtVomit2.png?raw=true);
}

#project-1,
#project-2,
#project-3,
#project-4 {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.project-tile {
  width: 300px;
  height: 300px;
  line-height: 300px;
  color: transparent;
  margin: 20px;
  box-shadow: 3px 3px 3px 3px #ccc;
  font-family: "Montserrat", sans-serif;
}

.project-icon {
  height: 25px;
  width: 25px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 600px;
  padding: 2rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  border: none;
}

.modal-icon {
  height: 20px;
  width: 20px;
  margin: 0 10px;
}

#modal-project-title {
  text-align: center;
  font-family: "Paytone One", sans-serif;
}

.modal-img {
  max-width: 600px;
}

.modal-text {
  font-family: "Quicksand", sans-serif;
}

.show-modal-button {
  width: 300px;
  height: 300px;
  border: none;
  color: rgb(249, 249, 252);
  font-weight: 600;
  opacity: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}

.show-modal-button:hover {
  opacity: 1;
  background: rgba(79, 67, 67, 0.6);
  cursor: pointer;
}

.project-site-link {
  color: rgb(61, 61, 143);
  margin: 0 10px;
  line-height: 100%;
  text-align: center;
}

.project-site-link:hover {
  color: rgb(139, 139, 177);
}

@media screen and (max-width: 800px) {
  .project-tile {
    margin: 30px auto;
    width: 60vw;
    height: 60vw;
    line-height: 60vw;
  }
  .show-modal-button {
    width: 60vw;
    height: 60vw;
    opacity: 1;
    background: rgba(79, 67, 67, 0.6);
  }
}

@media screen and (max-width: 800px) {
  .modal-img {
    width: 100%;
  }
}
