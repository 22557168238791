@import url(https://fonts.googleapis.com/css2?family=Paytone+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* font-family: 'Paytone One', sans-serif; */
/* font-family: 'Montserrat', sans-serif; */
/* font-family: 'Quicksand', sans-serif; */

.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  /* position: absolute; */
}

a {
  text-decoration: none;
}

#about:hover,
#resume:hover,
.contact:hover,
#header-projects:hover,
#header-resume:hover,
#header-about:hover,
.link:hover {
  cursor: pointer;
  color: rgb(139, 139, 177);
}

.page-title {
  font-family: "Paytone One", sans-serif;
  font-size: 35px;
  color: rgb(245, 171, 43);
  margin-top: 60px;
}

@media screen and (max-width: 800px) {
  .page-title {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1400px) {
  .App {
    width: 1400px;
    height: auto;
    margin: 0 auto;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 30px 80px;
}

#header-projects,
#header-resume,
#header-about,
.link {
  color: rgb(61, 61, 143);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.header-option {
  margin: 0px 10px;
}

@media screen and (max-width: 800px) {
  .header {
    flex-direction: column;
    align-items: center;
  }
  .header-title {
    margin-bottom: 10px;
    width: 80vw;
  }
  .header-option {
    margin: 20px 10px;
  }
}

@media screen and (min-width: 1400px) {
  .header {
    max-width: 1400px;
    margin: 0 auto;
  }
}

.footer-container {
  width: 100%;
  height: 100px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  bottom: 0;
  background: none;
}

.icon {
  height: 40px;
  width: 40px;
  margin: 10px;
  fill: rgb(65, 105, 225);
}

.icon:hover {
  height: 45px;
  width: 45px;
}

#linked-in-id {
  margin-right: 5vw;
}

.link,
.contact {
  margin-right: 20px;
}

.contact {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: rgb(61, 61, 143);
}

@media screen and (max-width: 500px) {
  .footer-container {
    justify-content: space-around;
    position: relative;
  }
  #linked-in-id {
    margin-right: 0;
  }
  .icon {
    width: 30px;
    height: 30px;
  }
}

@media screen and (min-width: 1400px) {
  .footer-container {
    max-width: 1400px;
    position: relative;
    margin: 0 auto;
  }
}

.my-photo {
  margin-right: 50px;
  width: 400px;
  -webkit-clip-path: polygon(
    50% 0%,
    90% 20%,
    100% 60%,
    75% 100%,
    25% 100%,
    0% 60%,
    10% 20%
  );
          clip-path: polygon(
    50% 0%,
    90% 20%,
    100% 60%,
    75% 100%,
    25% 100%,
    0% 60%,
    10% 20%
  );
}

.about-page {
  height: 100vh;
}

.about-all {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.about-text {
  width: 600px;
  text-align: left;
  font-family: "Quicksand", sans-serif;
  line-height: 1.5;
}

@media screen and (max-width: 800px) {
  .my-photo {
    margin-right: 0;
    width: 40vw;
  }
  .about-all {
    flex-direction: column;
  }
}

.contact-me {
  width: 100%;
  height: 100vh;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 0 auto;
}

.form-label {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  margin-top: 10px;
  color: rgb(61, 61, 143);
}

#text-area {
  height: 150px;
  overflow: auto;
  padding: 10px;
}

.form-input {
  margin-top: 5px;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  line-height: 1.7;
  background-color: rgb(254, 241, 220);
  border-radius: 5px;
  border: none;
}

.submit-button {
  width: 100px;
  height: 30px;
  margin: 30px auto;
  font-family: "Paytone One", sans-serif;
  border: none;
  border-radius: 5px;
  color: rgb(254, 241, 220);
  background-color: rgb(61, 61, 143);
}

#project-1 {
  background-image: url(https://raw.githubusercontent.com/suttondemlong/portfolio/main/src/assets/ParksAlert.png);
}

#project-2 {
  background-image: url(https://raw.githubusercontent.com/suttondemlong/portfolio/main/src/assets/PaintedGrits.png);
}

#project-3 {
  background-image: url(https://raw.githubusercontent.com/suttondemlong/portfolio/main/src/assets/GreenHouse.png);
}

#project-4 {
  background-image: url(https://github.com/suttondemlong/portfolio/blob/main/src/assets/ThoughtVomit2.png?raw=true);
}

#project-1,
#project-2,
#project-3,
#project-4 {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.project-tile {
  width: 300px;
  height: 300px;
  line-height: 300px;
  color: transparent;
  margin: 20px;
  box-shadow: 3px 3px 3px 3px #ccc;
  font-family: "Montserrat", sans-serif;
}

.project-icon {
  height: 25px;
  width: 25px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 600px;
  padding: 2rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  border: none;
}

.modal-icon {
  height: 20px;
  width: 20px;
  margin: 0 10px;
}

#modal-project-title {
  text-align: center;
  font-family: "Paytone One", sans-serif;
}

.modal-img {
  max-width: 600px;
}

.modal-text {
  font-family: "Quicksand", sans-serif;
}

.show-modal-button {
  width: 300px;
  height: 300px;
  border: none;
  color: rgb(249, 249, 252);
  font-weight: 600;
  opacity: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}

.show-modal-button:hover {
  opacity: 1;
  background: rgba(79, 67, 67, 0.6);
  cursor: pointer;
}

.project-site-link {
  color: rgb(61, 61, 143);
  margin: 0 10px;
  line-height: 100%;
  text-align: center;
}

.project-site-link:hover {
  color: rgb(139, 139, 177);
}

@media screen and (max-width: 800px) {
  .project-tile {
    margin: 30px auto;
    width: 60vw;
    height: 60vw;
    line-height: 60vw;
  }
  .show-modal-button {
    width: 60vw;
    height: 60vw;
    opacity: 1;
    background: rgba(79, 67, 67, 0.6);
  }
}

@media screen and (max-width: 800px) {
  .modal-img {
    width: 100%;
  }
}

.projects-page {
  height: 100%;
  width: 100vw;
}

.projects-container {
  height: 100%;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 100px;
}

@media screen and (max-width: 800px) {
  .projects-container {
    height: 100%;
    align-items: center;
  }
  .footer-container {
    position: relative;
  }
}

@media screen and (min-width: 1200px) {
  .projects-page {
    width: 1200px;
    margin: 0 auto;
  }
}

.landing-page {
  position: absolute;
  top: 0;
  background: transparent;
  height: 100vh;
  width: 100vw;
}

.triangle {
  margin-top: 75px;
  margin-left: 10vw;
  width: 0;
  height: 0;
  border-left: 200px solid transparent;
  border-right: 60vw solid transparent;
  border-top: 600px solid rgba(244, 5, 5, 0.75);
}

#name-blurb {
  position: absolute;
  width: 500px;
  top: 120px;
  left: 17%;
  color: white;
}

.my-name {
  font-family: "Paytone One", sans-serif;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 5px;
  color: rgb(247, 209, 245);
}

.blurb {
  font-family: "Quicksand", sans-serif;
  margin-top: 5px;
}

#projects {
  position: absolute;
  top: 20%;
  right: 15%;
  width: 450px;
  height: 450px;
  border-radius: 50%;
  background-color: rgb(65, 105, 225, 0.7);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(247, 209, 245);
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
}

#projects:hover,
.my-name:hover {
  cursor: pointer;
  color: rgb(171, 84, 166);
}

#resume {
  position: absolute;
  bottom: 31%;
  left: 10%;
  height: 60px;
  width: 60%;
  background-color: rgb(255, 255, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(61, 61, 143);
  font-family: "Quicksand", sans-serif;
  font-size: 20px;
}

#about {
  position: absolute;
  bottom: 21%;
  right: 10%;
  height: 60px;
  width: 60%;
  background-color: rgb(255, 255, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(61, 61, 143);
  font-family: "Quicksand", sans-serif;
  font-size: 20px;
}

@media screen and (max-width: 1350px) {
  #projects {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 1150px) {
  #name-blurb {
    top: 100px;
  }
  #projects {
    right: 5%;
  }
}

@media screen and (max-width: 1000px) {
  .triangle {
    margin-top: 50px;
    margin-left: 10vw;
  }
  #name-blurb {
    top: 50px;
  }
  #projects {
    width: 225px;
    height: 225px;
    top: 27%;
    right: 27%;
  }
}

@media screen and (max-width: 500px) {
  .landing-page {
    height: 100vh;
  }
  .triangle {
    border-right: 50vw solid transparent;
    border-left: 100px solid transparent;
  }
  #landing-my-name {
    font-size: 30px;
    width: 60%;
    margin-left: 10%;
  }
  #name-blurb {
    width: 100%;
    left: 0%;
    color: transparent;
  }
  #projects {
    right: 25px;
    top: 23%;
    width: 200px;
    height: 200px;
  }
  #resume,
  #about {
    width: 75%;
  }
}

@media screen and (min-width: 1400px) {
  .landing-page {
    width: 1400px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 2000px) {
  .triangle {
    border-right: 1200px solid transparent;
    margin-left: 0;
  }
}

