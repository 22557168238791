.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 30px 80px;
}

#header-projects,
#header-resume,
#header-about,
.link {
  color: rgb(61, 61, 143);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.header-option {
  margin: 0px 10px;
}

@media screen and (max-width: 800px) {
  .header {
    flex-direction: column;
    align-items: center;
  }
  .header-title {
    margin-bottom: 10px;
    width: 80vw;
  }
  .header-option {
    margin: 20px 10px;
  }
}

@media screen and (min-width: 1400px) {
  .header {
    max-width: 1400px;
    margin: 0 auto;
  }
}
