@import url("https://fonts.googleapis.com/css2?family=Paytone+One&display=swap");
/* font-family: 'Paytone One', sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
/* font-family: 'Montserrat', sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");
/* font-family: 'Quicksand', sans-serif; */

.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  /* position: absolute; */
}

a {
  text-decoration: none;
}

#about:hover,
#resume:hover,
.contact:hover,
#header-projects:hover,
#header-resume:hover,
#header-about:hover,
.link:hover {
  cursor: pointer;
  color: rgb(139, 139, 177);
}

.page-title {
  font-family: "Paytone One", sans-serif;
  font-size: 35px;
  color: rgb(245, 171, 43);
  margin-top: 60px;
}

@media screen and (max-width: 800px) {
  .page-title {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1400px) {
  .App {
    width: 1400px;
    height: auto;
    margin: 0 auto;
  }
}
